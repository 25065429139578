import React from "react"
import { Button, Typography, Row, Col } from "antd"
import { Link } from "gatsby"
import styled from "styled-components"

import StrikedText from "./StrikedText"

const { Title, Paragraph, Text } = Typography
export default class Footer extends React.Component {
  public render() {
    return (
      <FooterContainer>
        <section className="footer-section" id="scroll-link-2">
          <div className="wrapper">
          <Row gutter={[16, 16]}>
              <Col span={12} xs={24} sm={24} md={12} className="aligned-1">
              Jetzt innerhalb von <StrikedText text="3 Minuten" textColor="#fff" strikeColor="#ffc927" type=""/> <br/>
               Ihren Umzug online anfragen.
              </Col>
              <Col span={12} xs={24} sm={24} md={12} className="aligned-2">
                <Button type="primary" size="large">
                  <Link to="/form/">Kostenloses Angebot erhalten</Link>
                </Button>
              </Col>
              <Col span={12} xs={24} sm={24} md={12} className="aligned-1">
                <div className="contact-title">
                  <div className="small-text">Kontakt:</div>
                </div>
                <StrikedText text="info@easypak-umzug.de" textColor="#fff" strikeColor="#ffc927" type="mailto"/>
                <br/>
                <a className="footer-telephone" href="tel:07141 9163600"> 07141 9163600</a>
              </Col>
              <Col span={24} xs={24} sm={24} md={24} className="aligned-2">
                <div className="footer-links-wrapper">
                  <span className="small-text"><Link to="/datenschutz/"> <span>Datenschutz</span> </Link>  |  <Link to="/impressum/"> <span>Impressum</span></Link></span>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </FooterContainer>
    )
  }
}

const FooterContainer = styled.footer`
    background-color: #000000;
    color: #ffffff;
    font-size: 24px;
    @media (min-width: 1500px) {
      font-size: calc(24px * 1.4)!important;
    }
    font-weight: 600;

    .footer-section .wrapper {
      padding-bottom: 150px;
      max-width: 800px;
      @media (min-width: 1500px) {
        max-width: calc(800px * 1.4);
      }
      padding-top: 20px;
      line-height: 130%;
    }
    .contact-title {
      line-height: 80%;
      margin-top: 24px;
    }
    .footer-links-wrapper {
      display: block;
      float: right;
      margin-top: -50px;
    }
    .small-text {
      font-size:16px;
      @media (min-width: 1500px) {
        font-size: calc(14px * 1.4)!important;
      }
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
    }
    .footer-telephone{
      color: inherit;
      &:hover{
        color: #ffc927;
      }
    }
    .aligned-1 {
      text-align:left;
    }
    .aligned-2 {
      text-align:right;
    }
    #scroll-link-1{
      scroll-margin-top: 90px;
    }

    a {
      color: rgba(255,255,255,0.65);
      :hover {
        color: #ffc927;
      }
    }

    @media (max-width: 768px) {
      .aligned-1, .aligned-2 {
        text-align:center;
      }
    }
    @media (max-width: 480px){
      .footer-links-wrapper {
        clear: both;
        width: 100%;
        margin-top: 0;
      }
      .footer-section .wrapper {
        padding-bottom: 5px;
      }
  }
`