import React from "react"
import styled from "styled-components"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import { Typography } from "antd"

const { Title, Paragraph, Text } = Typography

export default class Impressum extends React.Component<any, any> {
  public render() {
    return (
      <Layout>
        <SEO title="Impressum" />
        <SectionsWrapper>
          <section className="main-section">
            <div className="wrapper">
              <Paragraph>
                Hinweise gem. § 5 TMG: <br />
                <Text strong>easypak Umzüge GmbH</Text> <br />
                Bottwartalstr. 62 <br />
                71642 Ludwigsburg <br /><br />

                <Text strong> Telefon: <a href="tel:07141 9163600"> 07141 9163600</a> </Text><br />
                <Text strong>Fax: 07141 9163602</Text> <br /><br />

                <Text strong><a href="mailto:info@easypak-umzug.de"> info@easypak-umzug.de</a></Text><br />
                <Text strong><a href="https://www.easypak-umzug.de" target="_blank"> www.easypak-umzug.de</a></Text> <br /><br />

                Geschäftsführer: Bekim Iballi <br />
                Gerichtsstand: Stuttgart <br />
                Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                Umsatzsteuergesetz: folgt <br /><br />

                <Text strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV</Text> <br />
                Bekim Iballi <br />
                Anschrift siehe oben <br /><br />

                <Text strong>Urheberrecht und Bildnachweise</Text> <br />
                Die Inhalte von <a href="https://www.easypak-umzug.de" target="_blank"> www.easypak-umzug.de</a> sind – soweit nicht abweichend
                angegeben – urheberrechtlich geschützt. Verwendete Fotografien
                sind ggf. mit Bildnachweisen gekennzeichnet oder unten
                aufgeführt, soweit sie nicht selbst angefertigt wurden.<br /><br />

                <Text strong>Design und Umsetzung:</Text> <br />
                <Text strong><a href="https://www.wus.agency/" target="_blank"> www.wus.agency</a></Text><br />
              </Paragraph>
            </div>
          </section>
        </SectionsWrapper>
        <section></section>
      </Layout>
    )
  }
}

const SectionsWrapper = styled.div`
  padding-top: 130px;
  a {
    color: rgba(0, 0, 0, 0.65);
    :hover {
      color: #ffc927;
    }
  }
`
