import React from "react"
import { Row, Col } from "antd"
import { navigate } from 'gatsby'
import { MailOutlined } from "@ant-design/icons"

import styled from "styled-components"
import StrikedText from "./StrikedText"

const mainLogo = require("../images/logo.png")
const mobileIcon = require("../images/mobile_icon.png")
const emailIcon = require("../images/mail.svg")

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props)
    let pathname="";
    if (typeof window !== `undefined`) {
      pathname = window && window.location.pathname;
    }
    this.state = {
      showLink1: (pathname === "/")
    }
  }
  
  scrollToLink1 = () => {
    const anchor = document.querySelector('#scroll-link-1')
    anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }
  scrollToLink2 = () => {
    const anchor = document.querySelector('#scroll-link-2')
    anchor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }
  goToHomePage = () => {
   navigate('/');
  }
  render() {
    return (
      <HeaderContainer>
        <section>
          <div className="wrapper" id="header-wrapper">
            <Row justify="center" gutter={[8, 0]}>
              <Col span={6} xs={6} sm={6} md={0}>
                <a href="tel:07141 9163600">
                  <img src={mobileIcon} alt="Image not found"/>
                </a>
              </Col>
              <Col span={6} xs={11} sm={11} md={6}>
                <div className="logo-wrapper">
                  <div onClick={this.goToHomePage} className="logo-icon-wrapper"></div>
                  <div className="logo-background-circle"></div>
                </div>
              </Col>
              <Col span={11} xs={0} sm={0} md={11}>
                <nav>
                  <ul>
                    { this.state.showLink1 && <li>
                        <a onClick={this.scrollToLink1}>Unsere Leistungen</a>
                      </li>
                    }
                    <li>
                      <a onClick={this.scrollToLink2}>Kontakt</a>
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col span={6} xs={0} sm={0} md={6} className="right-wrapper">
                <StrikedText
                  text="07141 9163600"
                  textColor="#ffc927"
                  strikeColor="black"
                  type="tel"
                />
              </Col>
              <Col span={6} xs={6} sm={6} md={0} className="right-wrapper">
                <a href="mailto:info@easypak-umzug.de" style={{ position: "relative" }}>
                  <img src={emailIcon} style={{ width: "34px", height: "34px", opacity: "0.7"}} alt="Image not found"/>
                </a>
              </Col>
            </Row>
          </div>
        </section>
      </HeaderContainer>
    )
  }
}

export default Header

const HeaderContainer = styled.header`
  
  font-size: 24px;
  @media (min-width: 1500px) {
    font-size: calc(20px * 1.4) !important;
  }
  @media (max-width: 1500px) {
    height: 60px;
    line-height: 60px;
  }

  box-sizing: border-box;

  section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 10000;
  }

  section #header-wrapper {
    padding: 20px 0;
    @media (max-width: 1500px) {
      padding: 0;
    }

    img {
      width: 28px;
    }
  }

  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  nav li {
    display: inline-block;
    margin: 0;
    margin-right: 30px;
  }
  nav a {
    display: block;
    text-decoration: none;
    font-weight: 700;
    color: #000000;

    :hover {
      color: #ffc927;
    }
  }

  .right-wrapper {
    text-align: right;
  }

  .logo-wrapper {
    width: 120px;
    height: 70px;
    position: relative;
    transition: all .2s ease-in-out;

    :hover {
      transform: scale(1.1);
    }
  }

  .logo-icon-wrapper {
    width: inherit;
    height: inherit;
    background-position: 8px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-image: url(${mainLogo});
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 100;
    cursor:pointer;
  }

  .logo-background-circle {
    width: 140px;
    height: inherit;
    position: absolute;
    top: 25px;
    left: -10px;
    border-radius: 50%;
    background-color: white;
    z-index: 99;
  }

  @media (min-width: 1500px) {
    font-size: calc(20px * 1.4) !important;
    
    .logo-wrapper {
      width: 78%;
      height: 144%;
      margin-top: -20px;
    }
  
    .logo-background-circle {
      width: 150%;
      height: 580%;
      position: absolute;
      top: -380%;
      left: -37%;
      border-radius: 50%;
      background-color: white;
      z-index: 99;
    }
  }

  @media (max-width: 768px) {
    .logo-wrapper {
      margin: 0 auto;
    }
    .logo-icon-wrapper {
      top: 0;
    }
    .logo-background-circle {
      display: none;
    }
  }
`
