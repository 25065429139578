import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./Header"
import Footer from "./Footer"

class Layout extends React.Component {
  public render() {
    return (
      <LayoutContainer>
        <Header />
        <main>{this.props.children}</main>
        <Footer />
      </LayoutContainer>
    )
  }
}

export default Layout

const LayoutContainer = styled.div`
  section .wrapper {
    margin: 0 auto;
    max-width: 960px;
    @media (min-width: 1500px) {
      max-width: calc(960px * 1.4);
    }
    padding: 1.0875rem 1.0875rem 1.45rem;
  }
  .vertical-space-10 {
    height: 10px;
  }
`
